import { Navigate, Outlet } from "react-router-dom";
import { isLoggedInToQuill } from "features/auth/utils/common";
import { AuthContext } from "features/auth";
import { useContext } from "react";
import { QuillRoutes } from "config/routes";
import { LoadingPage } from "components/ui/LoadingPage/LoadingPage";
import { WorkspacesProvider } from "context/WorkspacesContext";

export function RequireAuth() {
  const { checkingAuthState } = useContext(AuthContext);

  if (checkingAuthState) {
    return <LoadingPage />;
  }

  if (!isLoggedInToQuill() && !checkingAuthState) {
    const fromSearchParam = new URLSearchParams({ from: window.location.pathname + window.location.search });

    return <Navigate to={`${QuillRoutes.getLogin()}?${fromSearchParam}`} replace />;
  }

  return (
    <WorkspacesProvider>
      <Outlet />
    </WorkspacesProvider>
  );
}
