import { useEffect, useState } from "react";
import { QuillProgressBar } from "../QuillProgressBar";
import { NotificationProgressProps } from "../types";
import { useClasses } from "./notificationProggress.styles";

export function NotificationProgress(props: NotificationProgressProps) {
  const { onToastTimerEnd, timeoutInSeconds } = props;
  const max = 100;
  const [value, setValue] = useState(max);
  const classes = useClasses();
  const validTimeout = timeoutInSeconds && timeoutInSeconds > 0;

  useEffect(() => {
    const intervalDelay = 100; // Update every 100ms

    if (!validTimeout) {
      return () => {};
    }

    // Calculate the decrement based on total time
    const intervalIncrement = max / ((timeoutInSeconds * 1000) / intervalDelay);

    if (value > 0) {
      const timeout = setTimeout(() => {
        setValue((v) => Math.max(v - intervalIncrement, 0));
      }, intervalDelay);

      return () => clearTimeout(timeout);
    }

    if (value === 0) {
      onToastTimerEnd(); // Call end event when the progress is 0
    }

    return () => {};
  }, [value, onToastTimerEnd, timeoutInSeconds, validTimeout]);

  return validTimeout ? <QuillProgressBar className={classes.notificationProgress} value={value} max={max} /> : <></>;
}
