import { makeStyles } from "@fluentui/react-components";

export const useClasses = makeStyles({
  notificationToast: {
    padding: "16px",
    borderRadius: "4px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    backgroundColor: "white",
  },

  toastBody: {
    lineHeight: "20px",
  },

  dismissButton: {
    width: "20px",
    minWidth: "20px",
    padding: "0",
  },
});
