import { QuillButton } from "components/ui/QuillButton";
import { useClasses } from "./errorFallback.styles";

export function ErrorFallback() {
  const classes = useClasses();
  return (
    <div className={classes.pending}>
      <h1>Something went wrong</h1>
      <div>
        <QuillButton onClick={() => window.location.assign(window.location.href)}>Refresh</QuillButton>
      </div>
    </div>
  );
}
