import { AxiosInstance } from "axios";
import { QuillEndpoints } from "./endpoints";

export class QuillUser {
  register(user: UserReq, axiosClient: AxiosInstance) {
    return axiosClient.post(QuillEndpoints.registerUser(), user);
  }
}

type UserReq = {
  email: string;
  uid: string;
  firstName: string;
  lastName: string;
};
