import { useClasses } from "./drawerTop.styles";
import { QuillRoutes } from "config/routes";
import { NavLink } from "react-router-dom";
import { AddSquare20Regular, Checkmark20Regular, ChevronUpDownRegular } from "@fluentui/react-icons";
import { QuillText } from "components/ui/QuillText";
import {
  QuillMenu,
  QuillMenuButton,
  QuillMenuItem,
  QuillMenuList,
  QuillMenuPopover,
  QuillMenuTrigger,
} from "components/ui/QuillMenu";
import { Divider, mergeClasses } from "@fluentui/react-components";
import { Workspace } from "features/workspaces/types";
import { useContext, useState } from "react";
import { WorkspacesContext } from "context/WorkspacesContext";
import { QuillPersona } from "components/ui/QuillPersona";
import { CreateWorkspaceDialog } from "features/workspaces/CreateWorkspaceDialog";

export function DrawerTop() {
  const classes = useClasses();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { workspacesData, currentWorkspace } = useContext(WorkspacesContext);

  const getWorkspacesMenuItems = () => {
    return workspacesData.map((workspace: Workspace) => {
      const { id, name } = workspace;
      if (currentWorkspace?.id === id) {
        return (
          <NavLink key={id} to={QuillRoutes.getPreviews(id)} className={getDropdownLinkClassName}>
            <QuillMenuItem
              className={mergeClasses(classes.dropdownItem, classes.currentDropdownItem)}
              secondaryContent={<Checkmark20Regular className={classes.currentDropdownItemIcon} />}
            >
              <QuillPersona
                avatar={{ shape: "square" }}
                textPosition="after"
                textAlignment="center"
                name={name}
                primaryText={<QuillText weight="semibold">{name}</QuillText>}
              />
            </QuillMenuItem>
          </NavLink>
        );
      }
      return (
        <NavLink key={id} to={QuillRoutes.getPreviews(id)} className={getDropdownLinkClassName}>
          <QuillMenuItem className={classes.dropdownItem}>
            <QuillPersona
              avatar={{ shape: "square" }}
              textPosition="after"
              textAlignment="center"
              name={name}
              primaryText={<QuillText weight="semibold">{name}</QuillText>}
            />
          </QuillMenuItem>
        </NavLink>
      );
    });
  };

  const getDropdownLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return isActive ? classes.dropdownNavLinkActive : classes.dropdownNavLink;
  };

  const handleAddWorkspaceClick = () => {
    setDialogOpen(true);
  };

  return (
    <div className={classes.dropdownWrapper}>
      <CreateWorkspaceDialog open={dialogOpen} setOpen={setDialogOpen} />
      <QuillMenu>
        <QuillMenuTrigger disableButtonEnhancement>
          <QuillMenuButton
            className={classes.dropdown}
            appearance="transparent"
            shape="rounded"
            menuIcon={{ children: <ChevronUpDownRegular />, className: classes.dropdownIcon }}
          >
            <QuillPersona
              className={classes.selectedDropdownItem}
              size="small"
              avatar={{ shape: "square" }}
              textPosition="after"
              textAlignment="center"
              name={currentWorkspace?.name}
              primaryText={{
                children: (
                  <QuillText wrap={false} truncate weight="semibold" className={classes.selectedDropdownItemText}>
                    {currentWorkspace?.name}
                  </QuillText>
                ),
                className: classes.selectedDropdownItemValue,
              }}
            />
          </QuillMenuButton>
        </QuillMenuTrigger>

        <QuillMenuPopover className={classes.dropdownPopover}>
          <QuillMenuList>
            {getWorkspacesMenuItems()}

            <div className={classes.addWorkspaceMenuItem}>
              <Divider />
              <QuillMenuItem
                icon={<AddSquare20Regular />}
                className={classes.dropdownItem}
                onClick={handleAddWorkspaceClick}
              >
                Add a Workspace{" "}
              </QuillMenuItem>
            </div>
          </QuillMenuList>
        </QuillMenuPopover>
      </QuillMenu>
    </div>
  );
}
