import { createContext, useContext } from "react";

import { AxiosInstance } from "axios";
import { quillAxiosClient } from "utils/axios";

export const AxiosContext = createContext<AxiosInstance>(quillAxiosClient);

export function AxiosProvider({ children }: any) {
  return <AxiosContext.Provider value={quillAxiosClient}>{children}</AxiosContext.Provider>;
}

export const useAxios = () => {
  return useContext(AxiosContext);
};
