import { QuillButton } from "components/ui/QuillButton";
import {
  QuillDialog,
  QuillDialogSurface,
  QuillDialogBody,
  QuillDialogTitle,
  QuillDialogContent,
  QuillDialogActions,
  QuillDialogTrigger,
} from "components/ui/QuillDialog";
import { useClasses } from "./createWorkspaceDialog.styles";
import { Input, Label } from "@fluentui/react-components";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorCircle12Regular } from "@fluentui/react-icons";
import { useCreateWorkspace } from "services/workspaces/create-workspace";
import { QuillRoutes } from "config/routes";
import { ErrorBoundary } from "react-error-boundary";
import { QuillMessageBar } from "components/ui/QuillMessageBar/QuillMessageBar";
import { useEffect, useState } from "react";

type NewWorkspaceDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

type WorkspaceFormInputs = {
  name: string;
};

export function CreateWorkspaceDialog(props: NewWorkspaceDialogProps) {
  const classes = useClasses();
  const { open, setOpen } = props;
  const { register, handleSubmit, formState, reset } = useForm<WorkspaceFormInputs>();
  const [showMessage, setShowMessage] = useState(false);
  const createWorkspaceMutation = useCreateWorkspace({
    mutationConfig: {
      onSuccess: (data) => {
        if (data && data.id) {
          window.location.href = QuillRoutes.getPreviews(data.id);
        }
      },
    },
  });
  const { isPending, isError, mutate } = createWorkspaceMutation;

  const onSubmit: SubmitHandler<WorkspaceFormInputs> = (data) => {
    if (showMessage) {
      setShowMessage(false);
    }
    mutate(data);
  };

  const handleDialogOpenChange = (event: any, data: any) => {
    if (data.open === false) {
      setShowMessage(false);
    }
    setOpen(data.open);
  };

  useEffect(() => {
    if (isError) {
      setShowMessage(() => {
        // Reset the form state when the message is dismissed
        createWorkspaceMutation.reset();
        return true;
      });
    }
  }, [isError, open, reset, createWorkspaceMutation, showMessage, setShowMessage]);

  return (
    <QuillDialog open={open} onOpenChange={handleDialogOpenChange}>
      <QuillMessageBar
        title="There was an error creating your workspace. Please try again"
        intent="error"
        showMessage={showMessage}
        setShowMessage={setShowMessage}
      >
        <QuillDialogSurface>
          <ErrorBoundary fallback={<div>Sorry. An unexpected error occurred</div>}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <QuillDialogBody>
                <QuillDialogTitle>Add a new workspace</QuillDialogTitle>

                <QuillDialogContent className={classes.content}>
                  <div>Enter the name of your company or team</div>
                  <div className={classes.formInputs}>
                    <Label required htmlFor="workspace-name-input">
                      Workspace name
                    </Label>
                    <Input
                      type="text"
                      data-testid="workspace-name-input"
                      aria-invalid={formState.errors.name ? "true" : "false"}
                      id="workspace-name-input"
                      className={formState.errors.name && classes.formInputError}
                      {...register("name", {
                        required: "Workspace name is required",
                        maxLength: { value: 50, message: "Please use 50 characters or less" },
                      })}
                    />
                    {formState.errors.name?.type === "required" && (
                      <div className={classes.formInputErrorText} role="alert">
                        <ErrorCircle12Regular /> <span>Workspace name is required</span>
                      </div>
                    )}
                  </div>
                </QuillDialogContent>
                <QuillDialogActions>
                  <QuillDialogTrigger disableButtonEnhancement>
                    <QuillButton>Cancel</QuillButton>
                  </QuillDialogTrigger>

                  {isPending ? (
                    <QuillButton appearance="primary" type="submit" disabled>
                      Creating...
                    </QuillButton>
                  ) : (
                    <QuillButton appearance="primary" type="submit">
                      Create Workspace
                    </QuillButton>
                  )}
                </QuillDialogActions>
              </QuillDialogBody>
            </form>
          </ErrorBoundary>
        </QuillDialogSurface>
      </QuillMessageBar>
    </QuillDialog>
  );
}
