import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  dropdown: {
    width: "100%",
    borderRadius: "0",
    justifyContent: "start",
    ...shorthands.margin("0", "5px"),
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground3,
      color: tokens.colorNeutralForeground1,
    },

    ":hover:active": {
      color: tokens.colorNeutralForeground1,
      backgroundColor: tokens.colorNeutralBackground3Selected,
    },

    ":visited": {
      backgroundColor: tokens.colorNeutralBackground3,
      color: tokens.colorNeutralForeground1,
    },
  },

  dropdownIcon: {
    marginLeft: "auto",
    fontSize: "12px",
  },

  dropdownWrapper: {
    columnGap: "5px",
    display: "flex",
    ...shorthands.padding("0"),
  },

  dropdownPopover: {
    marginLeft: "10px !important",
    maxHeight: "calc(100vh - 110px)",
    width: "100%",
    paddingLeft: "0",
    paddingRight: "0",
    paddingTop: "0",
    paddingBottom: "0",
    border: "none",
  },

  dropdownItem: {
    maxWidth: "100%",
    borderRadius: "0",
    color: tokens.colorNeutralForeground1,
    ...shorthands.padding("15px", "20px"),
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },

  currentDropdownItem: {
    backgroundColor: tokens.colorNeutralBackground2,
  },

  currentDropdownItemIcon: {
    color: tokens.colorNeutralForeground1,
  },

  selectedDropdownItem: {
    display: "flex",
  },

  selectedDropdownItemValue: {
    alignContent: "center",
  },
  selectedDropdownItemText: {
    width: "155px",
    display: "block",
  },

  dropdownNavLink: {
    textDecoration: "none",
  },

  addWorkspaceMenuItem: {
    position: "sticky",
    bottom: "0",
  },

  dropdownNavLinkActive: {
    textDecoration: "none",
  },
});
