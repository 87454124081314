import { ErrorPage } from "features/auth";
import { createBrowserRouter } from "react-router-dom";
import { RequireAuth } from "./auth/RequireAuth";
import { Root } from "./Root";
import { RoutePaths } from "config/routes";
import { DashboardRoute } from "./dashboard/DashboardRoute";

export const createRouter = () =>
  createBrowserRouter([
    {
      element: <Root />,
      children: [
        {
          element: <RequireAuth />,
          children: [
            {
              path: RoutePaths.ROOT,
              lazy: async () => {
                const { WorkspacesRoute } = await import("./dashboard/WorkspacesRoute");
                return { Component: WorkspacesRoute };
              },
            },
            {
              path: RoutePaths.INVITE,
              lazy: async () => {
                const { InviteRoute } = await import("./InviteRoute");
                return { Component: InviteRoute };
              },
            },
            {
              path: RoutePaths.GIT_HOST_SETUP,
              lazy: async () => {
                const { GitHostSetUpRoute } = await import("./GitHostSetUpRoute");
                return { Component: GitHostSetUpRoute };
              },
            },
            {
              element: <DashboardRoute />,
              children: [
                {
                  path: RoutePaths.PREVIEWS,
                  lazy: async () => {
                    const { PreviewsRoute } = await import("./dashboard/PreviewsRoute");
                    return { Component: PreviewsRoute };
                  },
                },
                {
                  path: RoutePaths.PREVIEW,
                  lazy: async () => {
                    const { PreviewRoute } = await import("./dashboard/PreviewRoute");
                    return { Component: PreviewRoute };
                  },
                },
                {
                  path: RoutePaths.MEMBERS,
                  lazy: async () => {
                    const { MembersRoute } = await import("./dashboard/MembersRoute");
                    return { Component: MembersRoute };
                  },
                },
                {
                  path: RoutePaths.CREATE_FLOW,
                  lazy: async () => {
                    const { CreateFlowRoute } = await import("./dashboard/CreateFlowRoute");
                    return { Component: CreateFlowRoute };
                  },
                },
                {
                  path: RoutePaths.SETTINGS,
                  lazy: async () => {
                    const { SettingsRoute } = await import("./dashboard/SettingsRoute");
                    return { Component: SettingsRoute };
                  },
                  children: [
                    {
                      path: RoutePaths.SLACK_INTEGRATION,
                      lazy: async () => {
                        const { SlackIntegrationRoute } = await import("./dashboard/SlackIntegrationRoute");
                        return { Component: SlackIntegrationRoute };
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: RoutePaths.LOGIN,
          lazy: async () => {
            const { LoginRoute } = await import("./auth/LoginRoute");
            return { Component: LoginRoute };
          },
        },
      ],
      errorElement: <ErrorPage />,
    },
  ]);
