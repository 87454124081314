import { makeStyles } from "@fluentui/react-components";

export const useClasses = makeStyles({
  notificationProgress: {
    width: "100%",
    position: "absolute",
    top: "16px",
    left: "0",
  },
});
