import { Octokit } from "octokit";
import initializeOctokit from "utils/octokit";

import { BaseApiService } from "../api-service";
import {
  getPullRequestCommentMutation,
  getPullRequestListItemQuery,
  getPullRequestQuery,
} from "./github-queries.graphql";
import { PullRequestParams, PullRequest, NewComment, PullRequestBrief } from "../../types/pullRequest";
import { Preview } from "types/preview";

export class GitHubApiService implements BaseApiService {
  octokit: Octokit;

  constructor(token: string) {
    this.octokit = initializeOctokit(token);
  }

  updateClient(client: Octokit) {
    this.octokit = client;
  }

  // Update the client with a new token
  updateClientWithToken(token: string) {
    this.updateClient(initializeOctokit(token));
  }

  async getPullRequest(requestBody: PullRequestParams): Promise<void | PullRequest> {
    return this.octokit.graphql(getPullRequestQuery(requestBody)).then((result: any) => {
      const {
        id,
        number,
        titleHTML,
        bodyHTML,
        comments,
        url,
        headRefName,
        baseRefName,
        state,
        author,
        createdAt,
        mergedAt,
        closedAt,
        changedFiles,
        commits,
      } = result.repository.pullRequest;

      const pullRequest: PullRequest = {
        id,
        number,
        titleHTML,
        bodyHTML,
        comments: comments.edges,
        url,
        pullRequestBranch: headRefName,
        mergeBranch: baseRefName,
        state,
        author: {
          displayName: author.login,
          profileUrl: author.url,
          avatarUrl: author.avatarUrl,
        },
        createdAt: createdAt && new Date(createdAt),
        mergedAt: mergedAt && new Date(mergedAt),
        closedAt: closedAt && new Date(closedAt),
        totalChangedFiles: changedFiles,
        totalCommits: commits.totalCount,
      };

      return Promise.resolve(pullRequest);
    });
  }

  async getPullRequestListItem(preview: Preview): Promise<void | PullRequestBrief> {
    return this.octokit.graphql(getPullRequestListItemQuery(preview)).then((result: any) => {
      const {
        id,
        number,
        titleHTML,
        comments,
        repository,
        state,
        url,
        author,
        createdAt,
        mergedAt,
        closedAt,
        commits,
      } = result.repository.pullRequest;

      const pullRequestListItem: PullRequestBrief = {
        id,
        repository: repository.name,
        owner: repository.owner.login,
        url,
        number,
        titleHTML,
        commentCount: comments.totalCount,
        latestCommitHash: commits.nodes[0].commit.oid,
        latestCommitUrl: commits.nodes[0].commit.url,
        state,
        author: {
          displayName: author.login,
          profileUrl: author.url,
          avatarUrl: author.avatarUrl,
        },
        createdAt: createdAt && new Date(createdAt),
        mergedAt: mergedAt && new Date(mergedAt),
        closedAt: closedAt && new Date(closedAt),
      };

      return Promise.resolve(pullRequestListItem);
    });
  }

  async postPullRequestComment(requestBody: NewComment): Promise<void | object> {
    const { pullRequestId, body } = requestBody;
    return this.octokit
      .graphql(getPullRequestCommentMutation({ pullRequestId, body }))
      .then((result: any) => Promise.resolve(result.addComment.commentEdge.node));
  }
}
