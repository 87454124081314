import { queryOptions, useQuery } from "@tanstack/react-query";
import { QueryConfig } from "utils/reactQuery";
import { QuillEndpoints } from "client/backend/endpoints";
import { Workspace } from "features/workspaces/types";
import { useAxios } from "context/AxiosContext";
import { AxiosInstance } from "axios";

export const getWorkspaces = (axiosClient: AxiosInstance): Promise<Workspace[]> => {
  return axiosClient.get(QuillEndpoints.workspaces());
};

// Take advantage of TanStack's cacheing and query management
export const getWorkspacesQueryOptions = (axiosClient: AxiosInstance) =>
  queryOptions({
    // TODO: Find out how to make this unique. Maybe add userId
    queryKey: ["workspaces"],
    queryFn: () => getWorkspaces(axiosClient),
  });

type UseWorkspacesOptions = {
  queryConfig?: QueryConfig<typeof getWorkspacesQueryOptions>;
};

export const useWorkspaces = ({ queryConfig }: UseWorkspacesOptions = {}) => {
  const axiosClient = useAxios();
  return useQuery({
    ...getWorkspacesQueryOptions(axiosClient),
    ...queryConfig,
  });
};
