import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QuillEndpoints } from "client/backend/endpoints";
import { Workspace } from "features/workspaces/types";
import { MutationConfig } from "utils/reactQuery";
import { getWorkspacesQueryOptions } from "./get-workspaces";
import { useAxios } from "context/AxiosContext";
import { AxiosInstance } from "axios";

type CreateWorkspaceInputSchema = {
  name: string;
};

export const createWorkspace = async (
  data: CreateWorkspaceInputSchema,
  axiosClient: AxiosInstance,
): Promise<Workspace> => {
  return axiosClient.post(QuillEndpoints.workspaces(), data);
};

type UseCreateWorkspaceOptions = {
  mutationConfig?: MutationConfig<typeof createWorkspace>;
};

export const useCreateWorkspace = ({ mutationConfig }: UseCreateWorkspaceOptions = {}) => {
  const queryClient = useQueryClient();
  const axiosClient = useAxios();

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    mutationFn: (newData) => createWorkspace(newData, axiosClient),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getWorkspacesQueryOptions(axiosClient).queryKey,
      });
      onSuccess?.(...args);
    },
    ...restConfig,
  });
};
