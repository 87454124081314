import { keepPreviousData, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { QuillEndpoints } from "client/backend/endpoints";
import { FIFTY_MINUTES } from "config/query";
import { useAxios } from "context/AxiosContext";
import { QueryConfig } from "utils/reactQuery";

type GetGitHubAppTokenSchema = {
  wid: string;
  installationId: string;
};

type GetGitHubAppTokenResponse = {
  expires_in: number;
  token: string;
};

export const getGitHubAppToken = (
  data: GetGitHubAppTokenSchema,
  axiosClient: AxiosInstance,
): Promise<GetGitHubAppTokenResponse | null> => {
  const { wid, installationId } = data;

  if (!wid) {
    return Promise.reject(new Error("No workspace id or installation id"));
  }

  if (!installationId) {
    return Promise.resolve(null);
  }

  return axiosClient.post(QuillEndpoints.getGitHubAppToken(), {
    wid: wid.toString(),
    installationId,
  });
};

// Take advantage of TanStack's caching and query management
export const getGitHubAppTokenQueryOptions = (
  workspaceId: string,
  installationId: string,
  axiosClient: AxiosInstance,
) =>
  queryOptions({
    queryKey: ["GitHubAppToken", workspaceId, installationId],
    queryFn: () => getGitHubAppToken({ wid: workspaceId, installationId }, axiosClient),
    staleTime: FIFTY_MINUTES, // since GitHub app tokens expire in 1 hour
    placeholderData: keepPreviousData,
  });

type UseGitHubAppTokenOptions = {
  queryConfig?: QueryConfig<typeof getGitHubAppTokenQueryOptions>;
  workspaceId: string;
  githubInstallationId: string;
};

export const useGitHubAppToken = ({ queryConfig, workspaceId, githubInstallationId }: UseGitHubAppTokenOptions) => {
  const axiosClient = useAxios();
  // use the useSuspenseQuery hook so the suspense boundary can wait for the query
  // Enable the query only when both the workspaceId and githubInstallationId are available
  const enableQuery = !!(workspaceId && githubInstallationId);
  return useSuspenseQuery({
    enabled: enableQuery,
    ...getGitHubAppTokenQueryOptions(workspaceId, githubInstallationId, axiosClient),
    ...queryConfig,
  });
};
