import { useClasses } from "./drawerContent.styles";
import { mergeClasses, useId } from "@fluentui/react-components";
import { QuillPersona } from "components/ui/QuillPersona";
import { QuillRoutes } from "config/routes";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "features/auth";
import { useContext } from "react";
import { NavDivider, NavItem } from "@fluentui/react-nav-preview";
import {
  bundleIcon,
  Phone20Filled,
  Phone20Regular,
  People20Filled,
  People20Regular,
  Settings20Filled,
  Settings20Regular,
  Flow20Filled,
  Flow20Regular,
} from "@fluentui/react-icons";
import { DashboardNavValue } from "./types";
import { QuillPopover, QuillPopoverTrigger, QuillPopoverSurface } from "components/ui/QuillPopover";
import { UserProfileContent } from "features/userProfile/UserProfileContent";
import { WorkspacesContext } from "context/WorkspacesContext";

const Previews = bundleIcon(Phone20Filled, Phone20Regular);
const Flows = bundleIcon(Flow20Filled, Flow20Regular);
const Members = bundleIcon(People20Filled, People20Regular);
const Settings = bundleIcon(Settings20Filled, Settings20Regular);

export function DrawerContent() {
  const classes = useClasses();
  const { signedInUser } = useContext(AuthContext);
  const { currentWorkspace } = useContext(WorkspacesContext);
  const workspaceId = currentWorkspace?.id;
  const id = useId();
  const { search } = useLocation();
  const showFlows = new URLSearchParams(search).get("showFlows");

  const getAvatarProperties = (): Object => {
    if (!signedInUser) {
      return {};
    }
    return {
      image: signedInUser.photoURL ? { src: signedInUser!.photoURL } : {},
      color: "brand",
    };
  };

  const getDrawerLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return isActive ? classes.drawerNavLinkActive : classes.drawerNavLink;
  };

  return (
    <>
      <NavLink to={QuillRoutes.getPreviews(workspaceId ?? "")} className={getDrawerLinkClassName}>
        <NavItem className={classes.drawerNavItem} as="button" icon={<Previews />} value={DashboardNavValue.previews}>
          Previews
        </NavItem>
      </NavLink>
      {showFlows && (
        <NavLink to={`${QuillRoutes.createFlow(workspaceId ?? "")}?showFlows=true`} className={getDrawerLinkClassName}>
          <NavItem className={classes.drawerNavItem} as="button" icon={<Flows />} value={DashboardNavValue.flows}>
            Flows
          </NavItem>
        </NavLink>
      )}

      <NavLink to={QuillRoutes.getMembers(workspaceId ?? "")} className={getDrawerLinkClassName}>
        <NavItem className={classes.drawerNavItem} as="button" icon={<Members />} value={DashboardNavValue.members}>
          Members
        </NavItem>
      </NavLink>

      <NavLink to={QuillRoutes.getSettings(workspaceId ?? "")} className={getDrawerLinkClassName}>
        <NavItem className={classes.drawerNavItem} as="button" icon={<Settings />} value={DashboardNavValue.settings}>
          Settings
        </NavItem>
      </NavLink>
      <div className={mergeClasses(classes.profileDrawerNavLink, classes.drawerNavLink)} data-testid="profile-popover">
        <NavDivider className={classes.navDivider} />
        <QuillPopover trapFocus positioning="above">
          <QuillPopoverTrigger disableButtonEnhancement>
            <NavItem className={classes.drawerNavItem} value="profile" as="button">
              <QuillPersona
                textPosition="after"
                textAlignment="center"
                name={signedInUser?.displayName ? signedInUser?.displayName : ""}
                primaryText={<b>{signedInUser?.displayName ? signedInUser?.displayName : ""}</b>}
                avatar={getAvatarProperties()}
              />
            </NavItem>
          </QuillPopoverTrigger>

          <QuillPopoverSurface
            tabIndex={-1}
            aria-labelledby={id}
            className={classes.profilePopover}
            data-testid="user-profile-content"
          >
            <UserProfileContent />
          </QuillPopoverSurface>
        </QuillPopover>
      </div>
    </>
  );
}
