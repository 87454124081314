import { Toast, ToastBody, ToastTitle, ToastTrigger } from "@fluentui/react-components";

import { NotificationToastProps } from "../types";

import { useClasses } from "./notificationToast.styles";
import { QuillButton } from "components/ui/QuillButton";
import { DismissRegular } from "@fluentui/react-icons";
import { NotificationProgress } from "./NotificationProgress";
import { useCallback } from "react";

export function NotificationToast(props: NotificationToastProps) {
  const classes = useClasses();
  const { title, description, toastId, dismissToast, timeoutInSeconds } = props;
  const dismiss = useCallback(() => (toastId ? dismissToast(toastId) : () => {}), [dismissToast, toastId]);

  return (
    <Toast className={classes.notificationToast}>
      <NotificationProgress onToastTimerEnd={dismiss} timeoutInSeconds={timeoutInSeconds} />
      <ToastTitle
        action={
          <ToastTrigger>
            <QuillButton className={classes.dismissButton} appearance="transparent">
              <DismissRegular />
            </QuillButton>
          </ToastTrigger>
        }
      >
        {title}
      </ToastTitle>
      <ToastBody className={classes.toastBody}>{description}</ToastBody>
    </Toast>
  );
}
