import { ToastId, useToastController } from "@fluentui/react-components";
import { NotificationToast } from "components/ui/NotificationToast/NotificationToast";
import { createContext } from "react";

import { NotificationContextValues, NotificationContextProps, NotifyUserOptions } from "./types";

const NotificationContext = createContext<NotificationContextValues>({
  notifyUser: () => {},
  dismissAllNotifications: () => {},
  dismissNotification: () => {},
  updateNotification: () => {},
});

const getTimeoutInSecondsForType = (type: string) => {
  switch (type) {
    case "success":
      return 5;
    case "warning":
      return 10;
    case "error":
      return -1;
    default:
      return -1;
  }
};

function NotificationProvider({ children, toasterId }: NotificationContextProps) {
  const { dispatchToast, dismissAllToasts, dismissToast, updateToast } = useToastController(toasterId);

  // A negative timeout means it will never be auto dismissed
  const notifyUser = (props: NotifyUserOptions) => {
    const {
      title = "",
      type = "info",
      description = "",
      position = "top-end",
      timeoutInSeconds = undefined,
      toastId = undefined,
    } = props;

    const timeout = timeoutInSeconds ?? getTimeoutInSecondsForType(type);

    dispatchToast(
      <NotificationToast
        title={title}
        description={description}
        dismissToast={dismissToast}
        toastId={toastId}
        timeoutInSeconds={timeout}
      />,
      {
        position,
        timeout: timeout * 1000, // convert to milliseconds
        intent: type,
        toastId: toastId ?? undefined,
      },
    );
  };

  const dismissAllNotifications = () => {
    dismissAllToasts();
  };

  const dismissNotification = (toastId: ToastId) => {
    dismissToast(toastId);
  };

  const updateNotification = (toastId: ToastId, options: NotifyUserOptions) => {
    const { title, description, type, position, timeoutInSeconds } = options;
    const updateOptions = {
      position: position ?? "top-end",
      intent: type,
      timeout: timeoutInSeconds ? timeoutInSeconds * 1000 : undefined,
      toastId,
      content: (
        <NotificationToast
          title={title}
          description={description}
          dismissToast={dismissToast}
          toastId={toastId}
          timeoutInSeconds={timeoutInSeconds}
        />
      ),
    };
    console.log("updateOptions", updateOptions);
    updateToast(updateOptions);
  };

  return (
    <NotificationContext.Provider
      value={{ notifyUser, dismissAllNotifications, dismissNotification, updateNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export { NotificationContext, NotificationProvider };
