import { createContext, useContext, useEffect, useState } from "react";

import { GitHostContextValues } from "../features/auth/types";
import { useGitHubAppToken } from "hooks/useGitHubAppToken";
import { WorkspacesContext } from "./WorkspacesContext";
import { ErrorFallback } from "components/ui/ErrorFallback/ErrorFallback";
import { LoadingPage } from "components/ui/LoadingPage/LoadingPage";
import { GitHubApiService } from "services/github/github-service";

const defaultCtxValues = {
  gitHubApiService: null,
  getTokenError: false,
  getTokenIsPending: true,
};

export const GitHostContext = createContext<GitHostContextValues>(defaultCtxValues);

export function GitHostProvider({ children }: any) {
  const { currentWorkspace } = useContext(WorkspacesContext);
  const workspaceId = currentWorkspace?.id ?? "";
  const githubInstallationId = currentWorkspace?.githubInstallationId ?? "";
  const [gitHubApiService, setGitHubApiService] = useState<GitHubApiService | null>(null);
  const { data, isError, isPending } = useGitHubAppToken({
    workspaceId,
    githubInstallationId,
  });

  useEffect(() => {
    if (data?.token) {
      setGitHubApiService(new GitHubApiService(data.token));
    }
  }, [data]);

  if (isPending) {
    return <LoadingPage />;
  }

  if (isError) {
    console.error("Error fetching GitHub App token");
    return <ErrorFallback />;
  }

  return (
    <GitHostContext.Provider
      value={{
        gitHubApiService,
        getTokenError: isError,
        getTokenIsPending: isPending,
      }}
    >
      {children}
    </GitHostContext.Provider>
  );
}
