import { createContext, useEffect, useState } from "react";

import { WorkspacesContextValues } from "./types";
import { Workspace } from "features/workspaces/types";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "features/auth";
import { useNavigate, useParams } from "react-router-dom";
import { QuillRoutes } from "config/routes";
import { LoadingPage } from "components/ui/LoadingPage/LoadingPage";
import { useWorkspaces } from "services/workspaces/get-workspaces";
import { QuillMessageBar } from "components/ui/QuillMessageBar/QuillMessageBar";

const defaultCtxValues = {
  workspacesData: [],
  currentWorkspace: null,
};

const WorkspacesContext = createContext<WorkspacesContextValues>(defaultCtxValues);

function WorkspacesProvider({ children }: any) {
  const workspacesQuery = useWorkspaces();
  const { data: workspacesData, error, isLoading } = workspacesQuery;
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(null);
  const { workspaceId: urlWorkspaceId } = useParams();
  const [showMessage, setShowMessage] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const getWorkspaceById = (workspaceId: string): Workspace | undefined => {
      if (!workspacesData) {
        return undefined;
      }
      return workspacesData.find((workspace: Workspace) => workspace.id.toString() === workspaceId);
    };

    const setCurrentWorkspaceWithChecks = (workspaceId: string) => {
      // Do some checks before setting the workspace
      const workspace = getWorkspaceById(workspaceId);
      if (!workspace) {
        throw new Error("Workspace does not exist");
      }
      setCurrentWorkspace(workspace);
    };

    if (!isLoading) {
      if (!workspacesData || workspacesData.length < 1) {
        navigate(QuillRoutes.getRoot());
      }

      if (urlWorkspaceId) {
        setCurrentWorkspaceWithChecks(urlWorkspaceId);
      }
    }
  }, [workspacesData, urlWorkspaceId, isLoading, navigate]);

  return (
    <WorkspacesContext.Provider
      value={{
        workspacesData: workspacesData ?? [],
        currentWorkspace,
      }}
    >
      <ErrorBoundary fallback={<ErrorPage />} onError={() => {}}>
        {error && (
          <QuillMessageBar
            title="An unexpected error occurred"
            description="Could not retrieve workspaces"
            intent="error"
            showMessage={showMessage}
            setShowMessage={setShowMessage}
          >
            <p />
          </QuillMessageBar>
        )}
        {isLoading ? <LoadingPage /> : children}
      </ErrorBoundary>
    </WorkspacesContext.Provider>
  );
}

export { WorkspacesContext, WorkspacesProvider };
