import ReactDOM from "react-dom/client";
import "web-streams-polyfill";

import "./index.css";
import reportWebVitals from "./utils/reportWebVitals";
import App from "app/App";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  // TODO: Enable StrictMode to catch potential issues in development
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

reportWebVitals(console.log);
