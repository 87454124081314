import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useClasses = makeStyles({
  drawerNavItem: {
    maxWidth: "100%",
    borderRadius: "0",
    ...shorthands.padding("15px", "20px"),

    ":hover": {
      backgroundColor: tokens.colorNeutralBackground3,
    },
    ":hover:active": {
      backgroundColor: tokens.colorNeutralBackground3Pressed,
    },
  },

  profilePopover: {
    left: "10px !important",
  },

  drawerNavLink: {
    textDecoration: "none",
    "& button": {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },

  navDivider: {
    margin: "0",
  },

  drawerNavLinkActive: {
    backgroundColor: tokens.colorNeutralBackground3,
    textDecoration: "none",

    "& div": {
      backgroundColor: tokens.colorNeutralBackground3,
      ":hover": {
        backgroundColor: tokens.colorNeutralBackground3,
      },
    },
  },

  profileDrawerNavLink: {
    marginTop: "auto",
  },
});
