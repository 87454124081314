import { BrandVariants, Theme, createLightTheme } from "@fluentui/react-components";

const quillTheme: BrandVariants = {
  10: "#030303",
  20: "#171717",
  30: "#252525",
  40: "#313131",
  50: "#3D3D3D",
  60: "#49494A",
  70: "#565657",
  80: "#636364",
  90: "#717171",
  100: "#7E7F7F",
  110: "#8C8D8D",
  120: "#9B9B9C",
  130: "#A9AAAB",
  140: "#B8B9B9",
  150: "#C7C8C9",
  160: "#D6D7D8",
};

export const quilLightTheme: Theme = {
  ...createLightTheme(quillTheme),
  colorNeutralForeground1: "#000",
  colorBrandBackground: "#000",
  colorCompoundBrandForeground1: "#000",
  colorCompoundBrandForeground1Hover: "#000",
  colorNeutralForeground2BrandSelected: "#000",
  colorCompoundBrandStroke: "#000",
  colorCompoundBrandStrokeHover: "#000",
};
