/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, CreateAxiosDefaults, InternalAxiosRequestConfig } from "axios";
import { auth } from "config/firebase";

export async function quillAuthRequestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.headers) {
    config.headers.Authorization = await auth.currentUser?.getIdToken();
    config.headers["content-type"] = "application/json";
  }
  config.withCredentials = true;
  return config;
}

export const getAxiosInstance = (
  customConfig: CreateAxiosDefaults<any> | undefined,
  authRequestInterceptor: any,
): AxiosInstance => {
  const axiosClient = axios.create(customConfig);

  axiosClient.interceptors.response.use(
    (response: any) => {
      return response.data;
    },
    (error: any) => {
      const message = error.response?.data?.message || error.message;
      console.error(error);
      console.log(message);

      return Promise.reject(error);
    },
  );
  axiosClient.interceptors.request.use(authRequestInterceptor);
  return axiosClient;
};

export const quillAxiosClient = getAxiosInstance(undefined, quillAuthRequestInterceptor);
