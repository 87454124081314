import { NewComment, PullRequestParams } from "types/pullRequest";
import { Preview } from "types/preview";

export const getPullRequestQuery = (requestBody: PullRequestParams): string => `{
    repository(owner: "${requestBody.owner}", name: "${requestBody.repo}") {
      pullRequest(number:${requestBody.number}) {
        id
        titleHTML
        baseRefName
        headRefName
        bodyHTML
        number
        changedFiles
        state
        createdAt
        mergedAt
        closedAt
        url
        author {
          login
          avatarUrl
          url
        }
        commits(last:1){ 
          totalCount
        }
        comments(last: 10) {
          edges {
            node {
              id
              bodyHTML
              createdAt
              author {
                login
                avatarUrl
                url
              }
            }
          }
        }
      }
    }
  }`;

export const getPullRequestListItemQuery = (preview: Preview) => {
  const { owner, repoName, prId } = preview;

  return `{
    repository(owner: "${owner}", name: "${repoName}") {
      pullRequest(number: ${prId}){
        id
        titleHTML
        number
        url
        createdAt
        closedAt
        mergedAt
        state
        repository {
          name
          owner{
            login
          }
        }
        commits(last:1){ 
          totalCount
          nodes {
            url
            commit {
              oid
            }
          }
        }
        author {
          login
          avatarUrl
          url
        }
        comments {
          totalCount
        }
      }
    }
  }`;
};

export const getPullRequestCommentMutation = (requestBody: NewComment) => `mutation AddComment {
    addComment(input:{subjectId:"${requestBody.pullRequestId}", body:"${requestBody.body}"}) {
      commentEdge {
        node {
          id
          bodyHTML
          createdAt
          author {
            login
            avatarUrl
            url
          }
        }
      }
      subject {
        id
      }
    }
  }`;
